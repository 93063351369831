<template>
  <div
    :id="fieldId"
    v-user-interaction.visible
    :data-default-content="showDefaultList.toString()"
    v-bind="{ ...$attrs, ...additionalProperties }"
    @interacted="userHasInteracted"
  >
    <slot v-if="showDefaultList" name="default-list" />
    <slot v-if="showRecommendationList" name="recommendations" :recommendations="recommendations" />
  </div>
</template>

<script>
/* eslint-disable no-undef */
import { userInteraction } from '@Foundation/directives/userInteraction/userInteraction';

export default {
  name: 'SfmcContainer',
  directives: {
    userInteraction,
  },
  props: {
    apiUrl: {
      type: String,
      required: true,
      default: '/apioap/sfmc-articles',
    },
    action: {
      type: String,
      required: true,
    },
    campaign: {
      type: String,
      required: true,
    },
    enabled: {
      type: Boolean,
      required: true,
    },
    fieldId: {
      type: String,
      default: () => {
        const uuid = 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
          const r = (Math.random() * 16) | 0,
            v = c === 'x' ? r : (r & 0x3) | 0x8;
          return v.toString(16);
        });

        return `sfmc-container-${uuid}`;
      },
    },
  },
  data() {
    return {
      success: false,
      loading: true,
      recommendations: [],
      experienceId: null,
      userGroup: null,
      pollingId: undefined,
    };
  },
  computed: {
    showDefaultList() {
      return this.loading ? false : !this.success;
    },
    showRecommendationList() {
      return this.loading ? false : this.enabled && this.success;
    },
    additionalProperties() {
      if (!this.experienceId || !this.userGroup) return {};

      return {
        'data-evg-experience-id': this.experienceId,
        'data-evg-user-group': this.userGroup,
        'data-evg-campaign-id': this.campaign,
      };
    },
  },
  methods: {
    getRecommendedArticles() {
      fetch(this.apiUrl, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json;charset=utf-8' },
        credentials: 'same-origin',
        body: JSON.stringify({
          payloadAction: this.action,
          campaignId: this.campaign,
          currentItemType: window.interactionStudioData
            ? window.interactionStudioData.itemType
            : '',
          currentItemId: window.interactionStudioData ? window.interactionStudioData.itemId : '',
        }),
      })
        .then((response) => response.json())
        .then((res) => {
          if (res.success) {
            this.recommendations = this.responseNormalize(res.data);
            this.success = res.data && res.data.length > 0;
            this.experienceId = res.experienceId;
            this.userGroup = res.userGroup;

            this.$nextTick(() => {
              this.polling();
            });
          } else {
            this.success = false;
          }
        })
        .catch((err) => console.error(err))
        .finally(() => {
          this.loading = false;
        });
    },
    userHasInteracted() {
      this.getRecommendedArticles();
    },
    responseNormalize(recommendationsArray) {
      const returnArray = [];

      recommendationsArray.forEach((recommendation) => {
        if (recommendation.imageSet) {
          const imageKeys = {};
          Object.keys(recommendation.imageSet).forEach((key) => {
            imageKeys[key] = this.parseImagePath(recommendation.imageSet[key]);
          });
          returnArray.push({ ...recommendation, imageSet: imageKeys });
        } else {
          returnArray.push(recommendation);
        }
      });

      return returnArray;
    },
    parseImagePath(imagePath) {
      const arrPath = imagePath.split(' ').filter((item) => item != '');
      return arrPath[1] ? arrPath[0] : imagePath;
    },
    polling() {
      this.pollingId = setInterval(() => {
        if (window.Evergage && window.Evergage.getCurrentPage().action) {
          this.emitISEvents();
          clearInterval(this.pollingId);
        }
      }, 400);
    },
    emitISEvents() {
      if (this.success && window.Evergage) {
        window.Evergage.cashDom(`#${this.fieldId}`).attr({
          'data-evg-campaign-id': this.campaign,
          'data-evg-experience-id': this.experienceId,
          'data-evg-user-group': this.userGroup,
        });

        document.dispatchEvent(
          new CustomEvent(window.Evergage.CustomEvents.OnTemplateDisplayEnd, {
            detail: {
              payload: {
                campaign: this.campaign,
                experience: this.experienceId,
                userGroup: this.userGroup,
              },
            },
          })
        );
      }
    },
  },
};
</script>
