<!-- eslint-disable vue/no-v-html -->
<template>
  <ul>
    <li v-for="(product, $key) in items" :key="$key" :class="cssClass">
      <div
        v-tag:productclick.dom
        v-tag:productimpression.dom
        class="oap-product-card -compressed"
        :class="{ '-rating-count-visible': displayReviewCount }"
        data-tag-wrapper
        data-tag-label="__pageCategory__"
      >
        <div
          class="oap-product-card__top"
          data-tag-product-identifier
          :data-tag-product-name="product.name"
          :data-tag-product-id="product.id"
          :data-tag-product-brand="product.brand"
          :data-tag-product-price="product.price"
          :data-tag-product-category="product.category"
          :data-tag-product-position="$key + 1"
          :data-tag-product-variant="product.variant"
          data-tag-product-list="highlight"
          :data-tag-product-dimension35="product.size"
          :data-tag-product-dimension36="product.shade"
          :data-tag-product-dimension38="product.rating"
          :data-tag-product-dimension48="product.EAN"
          :data-tag-product-dimension49="product.isModifaceActivated"
          data-tag-product-dimension91="none"
          :data-tag-product-dimension50="product.initialPrice"
        >
          <div class="oap-product-card__image-wrapper">
            <img
              v-srcset="product.imageSet"
              :alt="product.name"
              aria-hidden="true"
              src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw=="
            />
          </div>

          <a class="oap-product-card__link" :href="product.Url">
            <span class="oap-product-card__brand" v-html="product.brand" />
            <span class="oap-product-card__product">{{ product.name }}</span>
          </a>
        </div>
        <div class="oap-product-card__bottom">
          <div class="oap-product-card__rating">
            <div class="oap-product-card__rating-list">
              <span
                v-for="index in Number.parseInt(productRatingMaximum, 10)"
                :key="`star_index_${index}`"
                class="oap-product-card__star"
                :class="{ '-selected': index <= product.rating }"
              >
                <svg
                  width="13px"
                  aria-hidden="true"
                  height="13px"
                  viewBox="0 0 42 40"
                  version="1.1"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g stroke="none" stroke-width="1" fill-rule="evenodd">
                    <path
                      d="M32.1840261,37.568991 L28.3704261,25.1809655 L39.231509,16.2587574 L25.798253,16.1621556 L21,2.93285568 L16.201747,16.1621556 L2.76849099,16.2587574 L13.6295739,25.1809655 L9.81597389,37.568991 L21,29.957571 L32.1840261,37.568991 Z"
                      stroke-width="2"
                    ></path>
                  </g>
                </svg>
              </span>
            </div>
            <span class="oap-product-card__rating-average">
              <span class="isSrOnly" v-text="averageRatingLabel" />
              {{ product.rating }}/{{ productRatingMaximum }}
              {{ ratingCountText(product.reviewCount) }}
            </span>
          </div>

          <p class="oap-product-card__cta">{{ viewProductLabel }}</p>
        </div>
      </div>
    </li>
  </ul>
</template>

<script>
import { tag } from '../../../../../../Foundation/Core/code/Scripts/analyticsHandler/directives/tag';
import { srcset } from '../../../../../../Foundation/Core/code/Scripts/directives/srcset/srcset';

export default {
  name: 'ProductListModel',
  directives: {
    tag,
    srcset,
  },
  props: {
    displayReviewCount: {
      default: false,
      type: Boolean,
    },
    items: {
      type: Array,
      required: false,
      default: () => {
        return [];
      },
    },
    viewProductLabel: {
      type: String,
      required: true,
      default: 'view product',
    },
    averageRatingLabel: {
      type: String,
      required: false,
      default: 'Average rating:',
    },
    productRatingMaximum: {
      type: [Number, String],
      required: false,
      default: 5,
    },
    cssClass: {
      type: String,
      required: false,
      default: 'oap-slider__item',
    },
  },
  methods: {
    ratingCountText(reviewCount) {
      if (!this.displayReviewCount || !reviewCount) return '';
      return reviewCount >= 10_000 ? '(10K+)' : `(${reviewCount})`;
    },
  },
};
</script>
